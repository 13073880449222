import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"

//import Button from '../components/Button';
//import config from "../config";

const ContactPage = () => (
  <Layout>
    <SEO title="Contato" />
    <Section>
      <div className="container">
        <h2>Contato</h2>

        <div style={{maxWidth: '600px'}}>
        <span>Você pode nos contactar usando o formulário abaixo, através do email <a href="mailto:contato@sefazfacilbrasil.com.br">contato@sefazfacilbrasil.com.br</a> ou pelo nosso chat ao lado.</span>
        </div>
        <br/>
        <br/>

        <div style={{maxWidth: '600px'}}>
          <form method="POST" action="/process/contact">
            <div>
              <label for="email">E-mail <strong>*</strong></label>
              <input className="form-control" type="email" name="email" required="required" />
            </div>

            <div>
              <label for="message">Mensagem <strong>*</strong></label>
              <textarea className="form-control" name="message" rows="4"></textarea>
            </div>

            <br/>
            <button className="btn btn-success">
              Enviar
            </button>
            <br/>
          </form>
        </div>
      </div>
    </Section>
  </Layout>
)

export default ContactPage
